<template>
	<el-scrollbar class="uu-box">
		<div class="uu-breadcrumb">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ name: 'homeIndex' }">{{$t('seller.breadcrumb.home')}}</el-breadcrumb-item>
				<el-breadcrumb-item>{{$t('seller.breadcrumb.finishOpenShop')}}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="uu-open">
			<div class="root-container">
				<!-- 主体 -->
				<div class="root-main">
					<template v-if="userInfo.open_shop===2">
						<div class="result-item">
							<img class="item-icon" src="@/assets/images/seller/tps-272-227.png" alt="">
							<div class="item-label">{{$t('seller.shop.openText1')}}</div>
						</div>
					</template>
					<template v-if="userInfo.open_shop!==2">
						<div class="uu-open-head">
							<div class="uu-open-head-title">
								<i class="el-icon-success"></i>
								<span>{{$t('seller.shop.openText2')}}</span>
							</div>
							<router-link class="uu-open-head-info" :to="{ name: 'shopManage'}">
								<div class="item">
									<div class="item-label">{{$t('seller.formItem.shopName1')}}</div>
									<div class="item-value">{{userInfo.storeName}}</div>
									
								</div>
								<div class="item">
									<div class="item-label">{{$t('seller.formItem.account')}}</div>
									<div class="item-value">{{userInfo.userName}}</div>
								</div>
							</router-link>
						</div>
						<div class="uu-open-block">
							<div class="uu-open-block-title">{{$t('seller.shop.openTitle1')}}</div>
							<el-row :gutter="20">
								<el-col :span="12">
									<div class="uu-open-base">
										<div class="uu-open-base-flow">
											<div class="uu-open-base-head">{{$t('seller.shop.openItem1')}}</div>
											<div class="uu-open-base-block">
												<div class="item">
													<div class="item-label">{{$t('seller.formItem.mobile')}}</div>
													<div class="item-block">
														<el-input class="txt-input" v-model="mobile" maxlength="20" show-word-limit></el-input>
													</div>
												</div>
												<div class="item">
													<div class="item-label">{{$t('seller.formItem.verifyCode')}}</div>
													<div class="item-block">
														<el-input class="verify-input" v-model="verifyCode"></el-input>
														<img class="verify-img" @click="getVerifyCode" :src="verifyCodeImg" alt="" >
													</div>
												</div>
											</div>
										</div>
									</div>
								</el-col>
								<el-col :span="12">
									<div class="uu-open-base">
										<div class="uu-open-base-flow">
											<div class="uu-open-base-head">{{$t('seller.shop.openItem2')}}</div>
											<div class="uu-open-base-block">
												<div class="item">
													<div class="item-label">{{$t('seller.formItem.ownerCard')}}</div>
													<div class="item-block">
														<el-input class="txt-input" maxlength="18" show-word-limit v-model="ownerCard"></el-input>
													</div>
												</div>
												<div class="item">
													<div class="item-label">{{$t('seller.shop.openItem3')}}</div>
													<div class="item-block">
														<div class="item-card">
															<el-upload
																action="#"
																:show-file-list="false"
																v-loading="loadingUplaod1"
																:http-request="httpCardFrontRequest">
																	<img v-if="ownerCardImg1" :src="ownerCardImg1" class="card-img">
																	<i v-else class="el-icon-plus card-img"></i>
															</el-upload>
															<div class="item-card-tit">{{$t('seller.shop.openTips1')}}</div>
														</div>
														<div class="item-card">
															<el-upload
																action="#"
																:show-file-list="false"
																v-loading="loadingUplaod2"
																:http-request="httpCardBackRequest">
																	<img v-if="ownerCardImg2" :src="ownerCardImg2" class="card-img">
																	<i v-else class="el-icon-plus card-img"></i>
															</el-upload>
															<div class="item-card-tit">{{$t('seller.shop.openTips2')}}</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</el-col>
							</el-row>
							<div class="uu-open-block-foot">
								<el-button type="uu_blue" @click="handleSumbit">{{$t('seller.actions.submitCertificate')}}</el-button>
							</div>
						</div>
					</template>
					<div class="uu-open-block">
						<div class="uu-open-block-title">{{$t('seller.shop.openTitle2')}}</div>
						<el-row :gutter="20">
							<el-col :span="12">
								<div class="uu-open-base">
									<div class="uu-open-base-label"><img class="block-img" src="@/assets/images/seller/2.png" alt=""></div>
									<div class="uu-open-base-block">
										<div class="uu-open-base-main">
											<div class="item-box">
												<div class="item-title">{{$t('seller.shop.openBase1-1')}}</div>
												<div class="item-text">{{$t('seller.shop.openBase1-2')}}</div>
											</div>
											<div class="item-action">
												<router-link :to="{ name: 'sellPublish' }">
													<el-button>{{$t('seller.actions.publish')}}</el-button>
												</router-link>
											</div>
										</div>
									</div>
								</div>
							</el-col>
							<el-col :span="12">
								<div class="uu-open-base">
									<div class="uu-open-base-label"><img class="block-img" src="@/assets/images/seller/12.png" alt=""></div>
									<div class="uu-open-base-block">
										<div class="uu-open-base-main">
											<div class="item-box">
												<div class="item-title">{{$t('seller.shop.openBase2-1')}}</div>
												<div class="item-text">{{$t('seller.shop.openBase2-2')}}</div>
											</div>
											<div class="item-action">
												<router-link :to="{ name: 'shopManage' }">
													<el-button>{{$t('seller.actions.toPerfect')}}</el-button>
												</router-link>
											</div>
										</div>
									</div>
								</div>
							</el-col>
						</el-row>
						<div class="uu-open-block-foot">
							<router-link :to="{ name: 'homeIndex' }">{{$t('seller.actions.enterSeller')}}</router-link>
						</div>
					</div>
				</div>
				<!-- 右侧 -->
				<div class="root-aside"></div>
				
			</div>
		</div>
		<el-dialog
			:visible.sync="dialogResultVisible"
			width="326px"
			class="dialog-result"
			:before-close="handleResultClose">
			<img class="icon" src="@/assets/images/seller/tps-350-300.png" alt="">
			<div class="title">{{$t('seller.shop.openAdopt')}}</div>
			<div class="subtitle"></div>
			<div><el-button type="primary" @click="handleResultClose" round>{{$t('seller.actions.enterOpenShop')}}</el-button></div>
		</el-dialog>
	</el-scrollbar>
</template>

<script>
	import { mapGetters } from 'vuex'
	import { applyVerifyCode, applyFinish } from '@/api/seller/index';
	import { normalOSSUpload } from '@/utils/upload';
	export default {
		computed: {
			...mapGetters([
				'userInfo'
			])
		},
		data() {
			return {
				mobile: '',
				ownerCard: '',
				ownerCardImg1: '',
				ownerCardImg2: '',
				loadingUplaod1: false,
				loadingUplaod2: false,
				verifyCode: '',
				verifyCodeImg: '',
				dialogResultVisible: false,
			}
		},
		created() {
			this.getVerifyCode();
			if(this.userInfo.open_shop==2){
				this.dialogResultVisible = true;
			}
		},
		methods: {
			getVerifyCode(){
				applyVerifyCode({uuid: this.$store.getters.uuid}).then(response => {
					this.verifyCodeImg = response.data.code_img;
				})
			},
			async httpCardFrontRequest(value){
				const { file } = value;
				const istype = ['image/jpeg','image/png'].includes(file.type);
				const isSize = file.size / 1024 / 1024 < 3;
				if (!istype) {
					this.$message.error(this.$t('seller.upload.imageType'));
					return false;
				}
				if (!isSize) {
					this.$message.error(this.$t('seller.upload.imageSize3M'));
					return false;
				}
				this.loadingUplaod1 = true;
				const { code, data } = await normalOSSUpload(file,'other');
				if(code==200){
					this.ownerCardImg1 = data.url;
				}else{
					this.$message.error(this.$t('seller.errorMsg.upload'))
				}
				this.loadingUplaod1 = false;
			},
			async httpCardBackRequest(value){
				const { file } = value;
				const istype = ['image/jpeg','image/png'].includes(file.type);
				const isSize = file.size / 1024 / 1024 < 3;
				if (!istype) {
					this.$message.error(this.$t('seller.upload.imageType'));
					return false;
				}
				if (!isSize) {
					this.$message.error(this.$t('seller.upload.imageSize3M'));
					return false;
				}
				this.loadingUplaod2 = true;
				const { code, data } = await normalOSSUpload(file,'other');
				if(code==200){
					this.ownerCardImg2 = data.url;
				}else{
					this.$message.error(this.$t('seller.errorMsg.upload'))
				}
				this.loadingUplaod2 = false;
			},
			handleSumbit(){
				let pattern = /^1[3-9]\d{9}$/
				if(!this.mobile) {
					this.$message.error(this.$t('seller.errorMsg.mobile'))
					return false
				}
				if(!pattern.test(this.mobile)){
					this.$message.error(this.$t('seller.errorMsg.mobileFormat'))
					return false
				}
				if(!this.verifyCode){
					this.$message.error(this.$t('seller.errorMsg.verifyCode'))
					return false
				}
				if(!this.ownerCard){
					this.$message.error(this.$t('seller.errorMsg.ownerCard'))
					return false
				}
				if(this.ownerCard.length<18){
					this.$message.error(this.$t('seller.errorMsg.ownerCardLength'))
					return false
				}
				if(!this.ownerCardImg1 || !this.ownerCardImg2){
					this.$message.error(this.$t('seller.errorMsg.ownerCardImg'))
					return false
				}
				let loading = this.$loading()
				let params = {
					owner_card: this.ownerCard,
					owner_card_image_1: this.ownerCardImg1,
					owner_card_image_2: this.ownerCardImg2,
					verify_code: this.verifyCode,
					store_name: this.userInfo.storeName,
					tel: this.mobile
				}
				applyFinish(params).then(response => {
					this.dialogResultVisible = true;
					this.$store.dispatch('user/getInfo');
					this.$message.success(this.$t('seller.successMsg.authenticate'));
					loading.close();
				}).catch((err)=>{
					this.getVerifyCode();
					loading.close();
				})
			},
			handleResultClose(){
				let loading = this.$loading();
				loading.close();
				this.dialogResultVisible = false;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.uu-breadcrumb {
		margin: 25px 20px 10px;
	}
	.uu-open {
		padding: 0 58px;
		.root-container {
			display: flex;
			flex-flow: row nowrap;
			.root-main {
				display: flex;
				flex: 1 0 750px;
				flex-flow: column;
				.result-item {
					margin-bottom: 44px;
					text-align: center;
					.item-icon {
						width: 102px;
						margin-bottom: 17px;
					}
					.item-label {
						font-size: 20px;
						color: #333333;
						text-align: center;
						line-height: 26px;
						margin-bottom: 17px;
					}
				}
				.uu-open-head {
					overflow: hidden;
					margin-top: 54px;
					margin-bottom: 71px;
					.uu-open-head-title {
						float: left;
						i {
							&.el-icon-success {
								color: #2bcf2b;
								font-size: 24px;
								margin-right: 20px;
							}
						}
						span {
							font-size: 24px;
						}
					}
					.uu-open-head-info {
						float: right;
						display: block;
						line-height: 20px;
						padding: 9px 18px 9px 24px;
						border-radius: 49px 0 0 49px;
						background-color: #eef5ff;
						.item {
							color: #666;
							display: flex;
							.item-value {
								color: #111;
								max-width: 142px;
								margin-left: 12px;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
						}
					}
				}
				.uu-open-block {
					.uu-open-block-title {
						font-size: 18px;
						line-height: 1;
						margin-bottom: 22px;
					}
					.el-row {
						display: flex;
					}
					.uu-open-base {
						padding: 23px;
						height: 100%;
						min-height: 165px;
						display: flex;
						background-color: #fff;
						border: 1px solid rgb(240,242,245);
						border-radius: 12px;
						box-sizing: border-box;
						
						.uu-open-base-flow {
							flex: 1;
							display: flex;
							flex-flow: column;
							.uu-open-base-head {
								text-align: center;
								font-size: 18px;
								padding-bottom: 20px;
								border-bottom: 1px dashed #eee;
								margin-bottom: 20px;
							}
							
							.uu-open-base-block {
								flex: 1;
								.item {
									display: flex;
									font-size: 14px;
									line-height: 40px;
									margin-bottom: 10px;
									.item-label {
										width: 90px;
										color: #999999;
									}
									.item-block {
										margin-left: 10px;
										flex: 1;
										.verify-input {
											width: 100px;
											margin-right: 10px;
										}
										.verify-img {
											height: 40px;
											width: 80px;
											cursor: pointer;
										}
									}
									.item-card {
										display: inline-block;
										vertical-align: middle;
										margin-right: 10px;
										.item-card-tit {
											text-align: center;
											font-size: 12px;
											color: #999999;
											line-height: 18px;
											margin-top: 8px;
										}
										&:last-child {
											margin-right: 0;
										}
										.card-img {
											width: 100px;
											height: 100px;
											object-fit: contain;
											line-height: 102px;
										}
										::v-deep .el-upload {
											border: 1px dashed #d9d9d9;
											border-radius: 4px;
										}
									}
									&:last-child {
										margin-bottom: 0;
									}
								}
							}
						}
						.uu-open-base-label {
							margin-right: 24px;
							font-size: 18px;
							line-height: 30px;
							text-align: center;
							display: inline-flex;
							align-items: center;
							.block-img {
								width: 42px;
								height: 42px;
							}
						}
						
						.uu-open-base-main  {
							display: flex;
							.item-box {
								flex: 1;
							}
							.item-title {
								font-size: 14px;
								color: #111111;
								line-height: 18px;
								margin-bottom: 10px;
							}
							.item-text {
								font-size: 12px;
								line-height: 19px;
								margin-bottom: 12px;
								color: #999999;
								text-align: justify;
							}
							.item-action {
								padding: 0 12px;
							}
						}
					}
					.uu-open-block-foot {
						margin-top: 40px;
						text-align: center;
					}
				}
			}
			.root-aside {
				display: flex;
				width: 370px;
				margin-left: 47px;
			}
		}
	}
	::v-deep .dialog-result {
		.el-dialog__body {
			height: 300px;
			text-align: center;
			.icon {
				width: 175px;
			}
			.title {
				margin-top: 17px;
				margin-bottom: 10px;
				font-size: 18px;
				color: #333333;
				text-align: center;
				line-height: 26px;
			}
			.subtitle {
				margin-bottom: 20px;
				font-size: 12px;
				text-align: center;
				line-height: 12px;
				color: rgba(102,102,102,.6);
			}
		}
	}
</style>
